@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .gradient-border-button {
    position: relative;
    background: #0A0A0A;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    color: white;
    z-index: 1;
  }

  .gradient-border-button::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 0.5rem;
    padding: 2px;
    background: linear-gradient(to right, rgb(96, 165, 250), rgb(22, 163, 74));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  .gradient-border-button:hover::before {
    background: linear-gradient(to right, rgb(59, 130, 246), rgb(34, 197, 94));
  }
} 